var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('el-page-header',{attrs:{"title":_vm.$l.getLanguageText('basic.return').toString(),"content":(_vm.dataId > 0
        ? _vm.$l.getLanguageText('basic.edit').toString()
        : _vm.$l.getLanguageText('basic.create').toString()) +
      ' ' +
      _vm.$l.getLanguageText('warehouseInStorageMain.warehousingOrder').toString()},on:{"back":function($event){return _vm.$router.back()}}}),_c('el-form',{ref:"dataForm",staticStyle:{"margin-top":"20px"},attrs:{"rules":_vm.roleRule,"model":_vm.form,"label-position":"top","label-width":"130px"}},[_c('h2',[_vm._v(_vm._s(_vm.$l.getLanguageText("warehouseInStorageMain.basicInfo")))]),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":_vm.$l
              .getLanguageText('warehouseInStorageMain.purchaseCode')
              .toString(),"prop":"purchaseCode"}},[_c('el-input',{attrs:{"placeholder":_vm.$l.getLanguageText('warehouseInStorageMain.tips.purchaseCode')},model:{value:(_vm.form.purchaseCode),callback:function ($$v) {_vm.$set(_vm.form, "purchaseCode", $$v)},expression:"form.purchaseCode"}})],1)],1),_c('el-col',{attrs:{"span":6}},[(_vm.isCreated)?_c('el-form-item',{attrs:{"label":_vm.$l
              .getLanguageText('warehouseInStorageMain.inStorageCode')
              .toString(),"prop":"inStorageCode"}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.form.inStorageCode),callback:function ($$v) {_vm.$set(_vm.form, "inStorageCode", $$v)},expression:"form.inStorageCode"}})],1):_vm._e()],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":_vm.$l
              .getLanguageText('warehouseInStorageMain.inStorageType')
              .toString(),"prop":"inStorageType"}},[_c('el-select',{attrs:{"placeholder":_vm.$l.getLanguageText('basic.select').toString(),"clearable":""},model:{value:(_vm.form.inStorageType),callback:function ($$v) {_vm.$set(_vm.form, "inStorageType", $$v)},expression:"form.inStorageType"}},[_c('el-option',{attrs:{"label":_vm.$l
                  .getLanguageText(
                    'warehouseInStorageMain.warehouseSelfLiftinPoint'
                  )
                  .toString(),"value":"PurchaseWarehousing"}})],1)],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":_vm.$l.getLanguageText('warehouseInStorageMain.supplier').toString(),"prop":"supplierId"}},[_c('el-select',{attrs:{"clearable":"","filterable":"","type":"text","placeholder":_vm.$l
                .getLanguageText('warehouseInStorageMain.tips.supplier')
                .toString()},model:{value:(_vm.form.supplierId),callback:function ($$v) {_vm.$set(_vm.form, "supplierId", $$v)},expression:"form.supplierId"}},_vm._l((_vm.supplierList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.supplierName,"value":item.id}})}),1)],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":_vm.$l
              .getLanguageText('warehouseInStorageMain.currentWarehouse')
              .toString(),"prop":"warehouseId"}},[_c('el-select',{attrs:{"clearable":"","filterable":"","type":"text","placeholder":_vm.$l
                .getLanguageText(
                  'warehouseInStorageMain.tips.currentWarehouse'
                )
                .toString()},on:{"change":function($event){return _vm.fetchWareHouseLocationList()}},model:{value:(_vm.form.warehouseId),callback:function ($$v) {_vm.$set(_vm.form, "warehouseId", $$v)},expression:"form.warehouseId"}},_vm._l((_vm.wareHouseList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":_vm.$l
              .getLanguageText('warehouseInStorageMain.currentLocation')
              .toString(),"prop":"warehouseLocationId"}},[_c('el-select',{attrs:{"clearable":"","filterable":"","type":"text","placeholder":_vm.$l
                .getLanguageText(
                  'warehouseInStorageMain.tips.currentLocation'
                )
                .toString()},model:{value:(_vm.form.warehouseLocationId),callback:function ($$v) {_vm.$set(_vm.form, "warehouseLocationId", $$v)},expression:"form.warehouseLocationId"}},_vm._l((_vm.wareHouseLocationList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.frame +
                _vm.$l.getLanguageText('warehouseInStorageMain.frame') +
                item.layer +
                _vm.$l.getLanguageText('warehouseInStorageMain.layer') +
                item.position +
                _vm.$l.getLanguageText('warehouseInStorageMain.position'),"value":item.id}})}),1)],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$l
              .getLanguageText('warehouseInStorageMain.inStorageTime')
              .toString(),"prop":"inStorageTime"}},[_c('el-date-picker',{attrs:{"type":"datetime","placeholder":_vm.$l
                .getLanguageText('warehouseInStorageMain.tips.inStorageTime')
                .toString(),"value-format":"yyyy-MM-dd HH:mm:ss","format":"yyyy-MM-dd HH:mm:ss"},model:{value:(_vm.form.inStorageTime),callback:function ($$v) {_vm.$set(_vm.form, "inStorageTime", $$v)},expression:"form.inStorageTime"}})],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleSelectCommodity(scope.$index, scope.row)}}},[_vm._v(_vm._s(_vm.$l.getLanguageText("warehouseInStorageMain.selectProduct"))+" ")])]}}])})],1)],1),_c('el-divider'),(_vm.productSkuList.length)?_c('el-row',[_c('el-table',{attrs:{"data":_vm.productSkuList}},[_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('basic.index').toString(),"type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":_vm.$l
              .getLanguageText('warehouseInStorageMain.productName')
              .toString(),"prop":"name"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.fkProduct ? scope.row.fkProduct.name : "")+" ")]}}],null,false,4112995025)}),_c('el-table-column',{attrs:{"label":_vm.$l
              .getLanguageText(
                'warehouseInStorageMain.productSpecParamMappingStr'
              )
              .toString(),"prop":"specParamMappingStr"}}),_c('el-table-column',{attrs:{"label":_vm.$l
              .getLanguageText('warehouseInStorageMain.productNumber')
              .toString(),"prop":"number"}}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('commodity.model'),"align":"center","prop":"model"}}),_c('el-table-column',{attrs:{"label":_vm.$l
              .getLanguageText('warehouseInStorageMain.productBrand')
              .toString(),"prop":"fkProduct.fkBrand.name"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.fkProduct.fkBrand ? scope.row.fkProduct.fkBrand.name : "")+" ")]}}],null,false,956746577)}),_c('el-table-column',{attrs:{"label":_vm.$l
              .getLanguageText('warehouseInStorageMain.productCategory')
              .toString(),"prop":"fkProduct.fkCategory.parentNamePath"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.fkProduct.fkCategory ? scope.row.fkProduct.fkCategory.name : "")+" ")]}}],null,false,639345681)}),_c('el-table-column',{attrs:{"label":_vm.$l
              .getLanguageText('warehouseInStorageMain.productUnit')
              .toString(),"prop":"fkUnit.displayName"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.fkUnit ? scope.row.fkUnit.displayName : "")+" ")]}}],null,false,3957565515)}),_c('el-table-column',{attrs:{"label":_vm.$l
              .getLanguageText(
                'warehouseInStorageMain.productInStorageQuantity'
              )
              .toString(),"prop":"inStorageQuantity"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"placeholder":_vm.$l
                  .getLanguageText(
                    'warehouseInStorageMain.tips.productInStorageQuantity'
                  )
                  .toString()},model:{value:(scope.row.inStorageQuantity),callback:function ($$v) {_vm.$set(scope.row, "inStorageQuantity", $$v)},expression:"scope.row.inStorageQuantity"}})]}}],null,false,343214202)}),_c('el-table-column',{attrs:{"label":_vm.$l
              .getLanguageText('warehouseInStorageMain.productCostPrice')
              .toString(),"prop":"costPrice"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"placeholder":_vm.$l
                  .getLanguageText('warehouseInStorageMain.productCostPrice')
                  .toString()},model:{value:(scope.row.costPrice),callback:function ($$v) {_vm.$set(scope.row, "costPrice", $$v)},expression:"scope.row.costPrice"}})]}}],null,false,4234851338)}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('basic.operation').toString()},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.handleDelete(scope.$index)}}},[_vm._v(_vm._s(_vm.$l.getLanguageText("basic.delete"))+" ")])]}}],null,false,4232931761)})],1)],1):_vm._e(),(_vm.productSkuList && _vm.productSkuList.length)?_c('el-row',{staticStyle:{"padding-top":"30px"},attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":_vm.$l
              .getLanguageText('warehouseInStorageMain.inStorageHandler')
              .toString(),"prop":"inStorageHandlerId"}},[_c('el-select',{attrs:{"clearable":"","type":"text","placeholder":_vm.$l
                .getLanguageText('warehouseInStorageMain.inStorageHandler')
                .toString()},model:{value:(_vm.form.inStorageHandlerId),callback:function ($$v) {_vm.$set(_vm.form, "inStorageHandlerId", $$v)},expression:"form.inStorageHandlerId"}},_vm._l((_vm.inStorageHandlerList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.realName,"value":item.id}})}),1)],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":_vm.$l
              .getLanguageText('warehouseInStorageMain.productIsWarehoused')
              .toString(),"prop":"isWarehoused"}},[_c('el-radio',{attrs:{"label":false},model:{value:(_vm.form.isWarehoused),callback:function ($$v) {_vm.$set(_vm.form, "isWarehoused", $$v)},expression:"form.isWarehoused"}},[_vm._v(_vm._s(_vm.$l.getLanguageText("warehouseInStorageMain.notWarehoused"))+" ")]),_c('el-radio',{attrs:{"label":true},model:{value:(_vm.form.isWarehoused),callback:function ($$v) {_vm.$set(_vm.form, "isWarehoused", $$v)},expression:"form.isWarehoused"}},[_vm._v(_vm._s(_vm.$l.getLanguageText("warehouseInStorageMain.warehoused"))+" ")])],1)],1)],1):_vm._e(),(_vm.productSkuList.length)?_c('el-row',{staticStyle:{"padding-top":"30px"},attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":_vm.$l
              .getLanguageText('warehouseInStorageMain.attachmentUpload')
              .toString(),"prop":""}},[(_vm.dataId)?_c('attachments-view',{attrs:{"host-id":_vm.dataId,"host-type":_vm.hostTypeFile}}):_vm._e(),_c('multiple-upload-file',{attrs:{"host-type":_vm.hostTypeFile,"host-id":_vm.dataId},model:{value:(_vm.form.savedAttachments),callback:function ($$v) {_vm.$set(_vm.form, "savedAttachments", $$v)},expression:"form.savedAttachments"}})],1)],1)],1):_vm._e()],1),_c('div',[_c('el-button',{attrs:{"type":"default"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$l.getLanguageText("basic.cancel")))]),_c('el-button',{attrs:{"type":"primary","disabled":_vm.bthDisabled},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$l.getLanguageText("basic.confirm"))+" ")])],1),_c('select-commodity',{ref:"editForm",on:{"productSkus":_vm.getSelectionProductSkus}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }