







































































































































import api from "@/api/index"; //ABP API接口
import { Component, Vue, Watch } from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView.vue";
import { createNgTree, INgNode } from "@/utils/tree";
import {
  ProductBasicParamDto,
  ProductCategoryDto,
  ProductDto,
} from "@/api/appService";

@Component({
  name: "CommodityList",
  components: {
    PagedTableView,
  },
})
export default class CommodityList extends Vue {
  queryForm = {
    name: "",
  };
  id = 0;
  categoryId = 0;
  // isShow = false;
  classificationList = [] as ProductCategoryDto[];
  classificationTree: INgNode<ProductCategoryDto>[] = [];
  parentIdPath = "";

  @Watch("parentIdPath")
  onValueChange(newVal: string, oldVal: string) {
    if (newVal !== oldVal) {
      (this as any).$bus.$emit("data-updated");
    }
  }

  created() {
    this.fetchClassificationList();
    this.fetchData(this.queryForm);
  }

  //获取全部商品类别
  async fetchClassificationList() {
    let _this = this as any
    await api.productCategory.getAll({
      skipCount: 0,
      maxResultCount: 65535
    }).then(res => {
      this.classificationList = res.items ?? []
      this.classificationTree = createNgTree(
        res.items ?? [],
        "parentId",
        "id",
        null,
        "children",
        null,
        false,
        "id",
        "fullName"
      );
      console.log(this.classificationTree)
    })
  }

  handleClassificationChange(data: any) {
    // if(data.isLeaf === true){
    // console.log(data);
    this.parentIdPath = data.data.parentIdPath;
    this.categoryId = data.id;
    // }
  }

  // 获取表数据
  fetchData(params: any) {
    // console.log("test:" + params);
    return api.product.getAll({ ...params, parentIdPath: this.parentIdPath });
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "commodityCreate",
      params: { id: "0" },
      query: { categoryId: String(this.categoryId) },
    });
  }

  // 编辑
  handleEdit(index: number, row: ProductBasicParamDto) {
    this.id = row.id!;
    this.$router.push({
      name: "commodityEdit",
      params: { id: row.id! + "" },
    });
  }

  handleProductMapping(index: number, row: ProductBasicParamDto) {
    this.id = row.id!;
    this.$router.push({
      name: "projectMapping",
      params: { id: row.id! + "" },
    });
  }

  // 删除
  async handleDelete(index: number, row: ProductDto) {
    this.$confirm(
      (this as any).$l.getLanguageText("basic.deleteTip") as string,
      (this as any).$l.getLanguageText("basic.tip") as string
    ).then(async () => {
      await api.product
        .delete({
          id: row.id,
        })
        .then(() => {
          this.$message({
            type: "success",
            message: (this as any).$l.getLanguageText(
              "basic.deleteSuccess"
            ) as string,
          });
        });
    });
  }
}
