














































































































































































import { Component, Ref, Vue } from "vue-property-decorator";

import api from "@/api";
import {
  ProductBrandDto,
  ProductCategoryDto,
  ProductSkuDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";
import { ElForm } from "element-ui/types/form";
import { createNgTree, INgNode } from "@/utils/tree";

@Component({
  name: "SelectCommodity",
  components: { PagedTableView },
})
export default class SelectCommodity extends Vue {
  @Ref() readonly dataForm!: ElForm;

  // @Prop({ required: true })
  // dataId!: number;

  queryForm = {
    model: undefined,
    number: undefined,
    name: undefined,
    parentIdPath: undefined,
    brandId: undefined,
  };
  show = false;
  loading = true;
  form: ProductSkuDto = {};
  selectionList = [];
  productSkus: number[] = [];
  productBrandList: ProductBrandDto[] = [];
  categories: ProductCategoryDto[] = [];
  categoriesTree: INgNode<ProductCategoryDto>[] = [];
  defaultData: ProductSkuDto = {
    id: 0,
  };

  save() {
    if (!this.selectionList || this.selectionList.length <= 0) {
      this.$message({
        type: "error",
        message: (this as any).$l
          .getLanguageText("warehouseInStorageMain.tips.selectIOneData")
          .toString(),
      });
      return;
    }

    this.productSkus = this.selectionList;
    console.log("productSkus=" + this.productSkus);
    this.$emit("productSkus", this.productSkus);
    this.show = false;
  }

  // 获取已选择数据
  getSelectionList(rows: any) {
    this.selectionList = rows;
  }

  created() {
    this.fetchProductBrandList();
    this.fetchCategories();
  }

  fetchProductBrandList() {
    api.productBrand.getAll({ maxResultCount: 65535 }).then((res: any) => {
      this.productBrandList = res!.items;
    });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.productSku.getAll(params);
  }

  fetchCategories() {
    api.productCategory.getAll({ maxResultCount: 1000 }).then((res) => {
      this.categories = res.items!;
      this.categoriesTree = createNgTree(
        res.items!,
        "parentId",
        "id",
        null,
        "children",
        null,
        false,
        "id",
        "name"
      );
    });
  }

  handleCategoriesChange(value: any) {
    this.queryForm!.parentIdPath = value![value.length - 1];
  }

  linkRule = {
    provinceId: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "warehouseInStorageMain.tips.province"
        ),
        trigger: "blur",
      },
    ],
    cityId: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "warehouseInStorageMain.tips.city"
        ),
        trigger: "change",
      },
    ],
  };

  cancel() {
    this.show = false;
  }
}
