


















































































































































































































































































































































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType,
  ProductSkuDto,
  SupplierDto,
  SysSettingTaxeDto,
  UserShortDto,
  WarehouseDto,
  WarehouseInStorageDetailCreateOrUpdateDto,
  WarehouseInStorageDetailDto,
  WarehouseInStorageMainCreateOrUpdateDto,
  WarehouseLocationDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import SelectCommodity from "@/views/warehouse/warehouseInStorageMain/selectCommodity.vue";
import CommodityList from "@/views/commodity/commodity/list.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile.vue";
import AttachmentsView from "@/components/AttachmentsView.vue";

interface ProductSkuExtend extends ProductSkuDto {
  inStorageQuantity?: number;
  costPrice?: number;
}

@Component({
  name: "WarehouseInStorageMainEdit",
  components: {
    AttachmentsView,
    MultipleUploadFile,
    CommodityList,
    SelectCommodity,
  },
})
export default class WarehouseInStorageMainEdit extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId = 0;
  isCreated = false;
  bthDisabled = false;
  defaultData: WarehouseInStorageDetailCreateOrUpdateDto = {};
  loading = true;
  form: WarehouseInStorageMainCreateOrUpdateDto = {};
  supplierList: SupplierDto[] = [];
  wareHouseList: WarehouseDto[] = [];
  wareHouseLocationList: WarehouseLocationDto[] = [];
  productSkuList: ProductSkuExtend[] = [];
  productSku: ProductSkuExtend = {
    costPrice: undefined,
    inStorageQuantity: undefined,
  };
  inStorageHandlerList: UserShortDto[] = [];

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      if (this.dataId > 0) this.isCreated = true;
      api.warehouseInStorageMain.get({ id: this.dataId }).then((res) => {
        this.form = { ...res! };
        api.wareHouseLocation
          .getAll({
            warehouseId: this.form!.warehouseId,
            maxResultCount: 65535,
          })
          .then((res: any) => {
            this.wareHouseLocationList = res!.items;
          });

        this.form.detailList?.forEach((item: any) => {
          api.productSku
            .get({
              id: item.fkProductSku?.id,
            })
            .then((res: any) => {
              this.productSku = { ...res! };
              this.productSku.inStorageQuantity = item.inStorageQuantity;
              this.productSku.costPrice = item.costPrice;
              this.productSkuList.push(this.productSku);
            });
        });
      });
    }
    this.fetchSupplierList();
    this.fetchWareHouseList();
    this.fetchWareHouseLocationList();
    this.fetchInStorageHandlerList();
  }

  save() {
    this.bthDisabled = true;
    (this.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.productSkuList && this.productSkuList.length > 0) {
          let inStorageDetailList: WarehouseInStorageDetailDto[] = [];
          this.productSkuList.forEach((item) => {
            if (!item.inStorageQuantity || item.inStorageQuantity <= 0) {
              this.$message(
                (this as any).$l
                  .getLanguageText(
                    "warehouseInStorageMain.tips.productCostPrice"
                  )
                  .toString()
              );
              this.bthDisabled = false;
              return false;
            }
            if (!item.costPrice || item.costPrice <= 0) {
              this.$message(
                (this as any).$l
                  .getLanguageText(
                    "warehouseInStorageMain.tips.productCostPrice"
                  )
                  .toString()
              );
              this.bthDisabled = false;
              return false;
            }
            if (this.dataId == 0) {
              inStorageDetailList.push({
                productSkuId: item.id,
                inStorageQuantity: item.inStorageQuantity,
                costPrice: item.costPrice,
              });
            }
          });
          if (this.form.detailList && this.form.detailList.length > 0) {
            this.form.detailList =
              this.form.detailList.concat(inStorageDetailList);
          } else {
            this.form.detailList = inStorageDetailList;
          }
        } else {
          this.$message(
            (this as any).$l
              .getLanguageText("warehouseInStorageMain.tips.selectProduct")
              .toString()
          );
          this.bthDisabled = false;
        }
        // console.log("detailList: " + JSON.stringify(this.form.detailList));
        if (this.dataId) {
          api.warehouseInStorageMain.update({ body: this.form }).then(() => {
            this.$router.back();
            this.$message.success(
              (this as any).$l.getLanguageText("basic.updateSuccess").toString()
            );
          });
        } else {
          api.warehouseInStorageMain.create({ body: this.form }).then(() => {
            this.$router.back();
            this.$message.success(
              (this as any).$l.getLanguageText("basic.createSuccess").toString()
            );
          });
        }
      }
      this.bthDisabled = false;
    });
  }

  fetchSupplierList() {
    api.supplier.getAll({ maxResultCount: 65535 }).then((res: any) => {
      this.supplierList = res!.items;
    });
  }

  fetchWareHouseList() {
    api.wareHouse.getAll({ maxResultCount: 65535 }).then((res: any) => {
      this.wareHouseList = res!.items;
    });
  }

  fetchWareHouseLocationList() {
    if (this.form.warehouseId != null) {
      api.wareHouseLocation
        .getAll({
          warehouseId: this.form!.warehouseId,
          maxResultCount: 65535,
        })
        .then((res: any) => {
          this.wareHouseLocationList = res!.items;
        });
    }
  }

  fetchInStorageHandlerList() {
    api.user.getAll({ maxResultCount: 65535 }).then((res: any) => {
      this.inStorageHandlerList = res!.items;
    });
  }

  // 选择商品
  handleSelectCommodity(index: number, row: SysSettingTaxeDto) {
    (this.$refs.editForm as any).show = true;
    (this.$refs.editForm as any).form = row;
  }

  getSelectionProductSkus(productSkuList: any) {
    if (productSkuList != null || productSkuList.length > 0) {
      // this.productSkuList = [];
      // this.productSkuList = productSkuList;
      productSkuList.forEach((item: any) => {
        let index = this.productSkuList.map((i) => i.id).indexOf(item.id);
        if (index === -1) {
          this.productSkuList.push(item);
        }
      });
    }
  }

  get hostTypeFile() {
    return AttachmentHostType.Warehousing;
  }

  cancel() {
    this.$router.back();
  }

  handleDelete(index: number) {
    this.productSkuList.splice(index, 1);
  }

  roleRule = {
    purchaseCode: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "warehouseInStorageMain.tips.purchaseCode"
        ),
        trigger: "blur",
      },
    ],
    inStorageType: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "warehouseInStorageMain.tips.inStorageType"
        ),
        trigger: "blur",
      },
    ],
    supplierId: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "warehouseInStorageMain.tips.supplier"
        ),
        trigger: "blur",
      },
    ],
    warehouseId: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "warehouseInStorageMain.tips.currentWarehouse"
        ),
        trigger: "blur",
      },
    ],
    warehouseLocationId: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "warehouseInStorageMain.tips.currentLocation"
        ),
        trigger: "blur",
      },
    ],
    inStorageTime: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "warehouseInStorageMain.tips.inStorageTime"
        ),
        trigger: "blur",
      },
    ],
    inStorageHandlerId: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "warehouseInStorageMain.tips.inStorageHandler"
        ),
        trigger: "blur",
      },
    ],
  };
}
