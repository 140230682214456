var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"close-on-click-modal":false,"title":_vm.$l.getLanguageText('warehouseInStorageMain.selectProduct').toString(),"visible":_vm.show,"width":"1550px"},on:{"update:visible":function($event){_vm.show=$event},"close":_vm.cancel}},[_c('paged-table-view',{attrs:{"search-function":_vm.fetchData,"query-form":_vm.queryForm},on:{"selectionList":_vm.getSelectionList},scopedSlots:_vm._u([{key:"condition",fn:function(){return [_c('el-form-item',[_c('el-input',{attrs:{"clearable":"","type":"text","placeholder":_vm.$l.getLanguageText('warehouseInStorageMain.productNumber')},model:{value:(_vm.queryForm.number),callback:function ($$v) {_vm.$set(_vm.queryForm, "number", $$v)},expression:"queryForm.number"}})],1),_c('el-form-item',[_c('el-input',{attrs:{"clearable":"","placeholder":_vm.$l.getLanguageText('commodity.enterCommodityModel')},model:{value:(_vm.queryForm.model),callback:function ($$v) {_vm.$set(_vm.queryForm, "model", $$v)},expression:"queryForm.model"}})],1),_c('el-form-item',[_c('el-input',{attrs:{"clearable":"","type":"text","placeholder":_vm.$l.getLanguageText('warehouseInStorageMain.productName')},model:{value:(_vm.queryForm.name),callback:function ($$v) {_vm.$set(_vm.queryForm, "name", $$v)},expression:"queryForm.name"}})],1),_c('el-form-item',[_c('el-cascader',{attrs:{"options":_vm.categoriesTree,"props":{ value: 'id' },"clearable":"","placeholder":_vm.$l
              .getLanguageText('warehouseInStorageMain.productCategory')
              .toString()},on:{"change":_vm.handleCategoriesChange},model:{value:(_vm.queryForm.parentIdPath),callback:function ($$v) {_vm.$set(_vm.queryForm, "parentIdPath", $$v)},expression:"queryForm.parentIdPath"}})],1),_c('el-form-item',[_c('el-select',{attrs:{"clearable":"","filterable":"","type":"text","placeholder":_vm.$l
              .getLanguageText('warehouseInStorageMain.productBrand')
              .toString()},model:{value:(_vm.queryForm.brandId),callback:function ($$v) {_vm.$set(_vm.queryForm, "brandId", $$v)},expression:"queryForm.brandId"}},_vm._l((_vm.productBrandList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)]},proxy:true},{key:"table",fn:function(){return [_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('basic.index').toString(),"type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('warehouseInStorageMain.productName').toString(),"align":"center","prop":"fkProduct.name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return [_vm._v(" "+_vm._s(row.fkProduct ? row.fkProduct.name : "")+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$l
            .getLanguageText(
              'warehouseInStorageMain.productSpecParamMappingStr'
            )
            .toString(),"align":"center","prop":"specParamMappingStr"}}),_c('el-table-column',{attrs:{"label":_vm.$l
            .getLanguageText('warehouseInStorageMain.productNumber')
            .toString(),"align":"center","prop":"number"}}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('commodity.model').toString(),"align":"center","prop":"model"}}),_c('el-table-column',{attrs:{"label":_vm.$l
            .getLanguageText('warehouseInStorageMain.productCategory')
            .toString(),"align":"center","prop":"fkProduct.categoryId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.fkProduct.fkCategory ? row.fkProduct.fkCategory.parentNamePath : "")+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('warehouseInStorageMain.productBrand').toString(),"align":"center","prop":"fkProduct.brandId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.fkProduct.fkBrand ? row.fkProduct.fkBrand.name : "")+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('warehouseInStorageMain.productUnit').toString(),"align":"center","prop":"unitId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.fkUnit ? row.fkUnit.displayName : "")+" ")]}}])})]},proxy:true}])}),_c('div',{staticClass:"button-position",staticStyle:{"align-content":"center"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"default"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$l.getLanguageText("basic.cancel")))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$l.getLanguageText("basic.confirm")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }